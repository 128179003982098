* {
    padding: 0;
}

html {
    height: 100%;
}

body {
    margin-top: 62px;
    padding-bottom: 50px;
}

main {
    margin: 0 14px;
    width: calc(100% - 28px);
}

h1 {
    padding-top: 1rem;
    font-weight: 700;
    font-size: 41px;
}

h2 {
    font-size: 24px;
    font-weight: 400;
    margin: 0;
}

h2 span {
    font-weight: 700;
    color: #ef4444;
}

.data-head {
    color: white;
}

.no-history {
    margin-bottom: 641px;
}

a {
    text-decoration: none;
    color: #ef4444;
}

p {
    margin: 0;
}

#US,
.trend,
.rate {
    display: block;
    margin: 0 auto;
    width: 100%;
}

#US {
    height: 50vh;
}

.overview-container + img {
    margin: 2rem 0;
    margin-bottom: 4rem;
}

.trend,
.rate {
    margin: 2rem 0;
}

.data-cd {
    margin-top: 1rem;
    display: flex;
    justify-content: space-evenly;
}

.data-cd div {
    margin-right: 20px;
}

.data-cd h3,
.slider-container h3 {
    font-size: 17px;
    color: white;
    margin: 0;
}

.data-cd p {
    font-weight: 500;
    font-size: 32px;
}

.view-control {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #433e43;
}

.view-control a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 60px;
    color: white;
    border-bottom: transparent solid 3px;
}

.view-control i {
    position: relative;
    bottom: 5px;
}

.view-control div .on {
    color: #ef4444;
    border-color: #ef4444;
}

.view-control div a {
    font-size: 30px;
    color: #9b9292;
    text-decoration: none;
}

.slider-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.slider {
    width: 80%;
    color: #ec4444;
}

.slider .MuiSlider-thumb:focus,
.slider .MuiSlider-thumb.Mui-active,
.slider .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 10px rgba(255, 0, 0, 0.2);
}

.aboutUs-img img {
    display: none;
}

.details {
    margin-bottom: 4rem;
}

.details:last-child {
    margin-bottom: 100px;
}

.details a {
    color: white;
    text-decoration: none;
}

.details a:hover {
    color: #f0f0f0;
    border-bottom: 0.1rem #f0f0f0;
}

.description-page h2 {
    text-decoration: underline;
    margin-bottom: 20px;
}

.description-img img {
    border-radius: 2.5%;
    width: 40%;
    margin: 20px;
}

.map-gif img {
    display: none;
    height: 350px;
    width: 450px;
    margin-bottom: 3rem;
}

.dashboard-top-img img {
    border: 2px solid white;
    width: 65%;
    margin: 15px;
}

.description-dashboard-img img {
    border: 1px solid white;
    margin: 15px;
}

.dashboard-container button {
    width: 180px;
    text-align: center;
    display: block;
    position: relative;
    background-color: #ec4444;
    transition: 0.3s;
    padding: 10px;
    margin: 20px auto;
    border-radius: 30px;
    border: none;
}

.dashboard-container button:disabled {
    background-color: rgb(93, 93, 93);
    color: #3f3c41;
}

.dashboard-container .enabled:hover {
    background-color: #ec4444a4;
    cursor: pointer;
}

.hoverStat {
    position: absolute;
    margin: 8px;
    padding: 4px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    max-width: 300px;
    font-size: 10px;
    z-index: 9;
    pointer-events: none;
}

#stateSVG {
    display: none;
}

.widget {
    background-color: #3f3c41;
    border-radius: 30px;
    margin-bottom: 20px;
    padding: 8px;
}

.widget h2 {
    padding: 1rem;
    font-weight: 600;
    color: white;
}

form h1 {
    margin-bottom: 50px;
}

form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

form input, form textarea {
    width: 100%;
}

form button {
    width: 100%;
}

@media (min-width: 992px) {
    body {
        height: calc(100% - 62px);
    }

    main {
        height: calc(100% - 40px);
        margin: 0 2rem;
        width: calc(100% - 4rem);
    }

    .view-control {
        display: none;
    }

    h1 {
        padding: 0;
        font-size: 3.5rem;
        margin-top: 40px;
    }

    h2 {
        font-size: 28px;
    }

    .overview {
        height: 100%;
    }

    .overview-container,
    .dashboard-container {
        width: calc(100% - 28px);
        margin: 0;
        padding: 0;
        display: flex;
        height: 100%;
    }

    .overview-container section,
    .dashboard-container section {
        margin: 0;
        height: 100%;
    }

    .overview-container section:first-child,
    .dashboard-container section:first-child {
        width: 25%;
    }

    .us-map {
        width: 60%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-height: 80%;
    }

    .data-cd {
        min-width: 60%;
        max-width: 75%;
        justify-content: space-between;
    }

    .overview-container section:first-child + section,
    .dashboard-container section:first-child + section {
        overflow: none;
        display: flex;
        height: 363px;
        width: 75%;
    }

    #US {
        height: calc(100vh - 122px);
    }

    .overview-container section:first-child + section img,
    .dashboard-container section:first-child + section img {
        margin: 0;
    }

    .overview-container + img {
        position: absolute;
        bottom: 50px;
    }

    #stateSVG {
        display: block;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
    }

    .rate {
        max-width: 50%;
    }

    .aboutUs-img img {
        display: block;
        margin: auto;
        width: 70%;
        margin-bottom: 20rem;
        border-radius: 10px;
    }

    .map-gif img {
        display: inline-block;
    }

    .widgets-container {
        width: calc(100% - 16px);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .widget {
        width: 600px;
    }

    .dashboard-container .button {
        display: inline-block;
    }
}

@media (min-width: 1328px) {
    .aboutUs-img img {
        width: 60%;
        margin-bottom: 6rem;
    }
}
